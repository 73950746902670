.burger-container {
  display: none;
}

.bar1,
.bar2,
.bar3 {
  width: 34px;
  height: 3px;
  background-color: #141518;
  margin: 5px 0;
  transition: 0.4s;
  border-radius: 50px;
}

.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-6px, 6px);
  transform: rotate(-45deg) translate(-6px, 6px);
}

.change .bar2 {
  opacity: 0;
}

.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-12px, -12px);
  transform: rotate(45deg) translate(-12px, -12px);
}

@media only screen and (max-width: 768px) {
  .burger-container {
    display: inline-block;
    position: absolute;
    left: 88%;
    cursor: pointer;
    padding-top: 1rem;
  }
}

@media only screen and (max-width: 400px) {
  .burger-container {
    display: inline-block;
    position: absolute;
    left: 88%;
    cursor: pointer;
    padding-top: 1rem;
  }
}
