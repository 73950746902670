@tailwind base;

/* Your own custom base styles */

/* Start purging... */
@tailwind components;
/* Stop purging. */

/* Your own custom component styles */

/* Start purging... */
@tailwind utilities;
/* Stop purging. */

/* Your own custom utilities */
.pointer-on-hover:hover {
    cursor: pointer;
  }
  
  .my-modal .modal-content {
    background-color: #15171a;
  }
  
  .sensor-dot:before {
    content: " ";
    display: inline-block;
    width: 12px;
    height: 12px;
    /* background: #ea4334; */
    border-radius: 8px;
    margin: 0 6px 0 7px;
    position: relative;
    top: 1px;
  }
  .sensor-dot:before:hover {
    width: 16px;
    height: 16px;
  }
  .sensor-dot {
    border-radius: 20px;
    padding-right: 8px;
    margin: -12px;
    color: transparent;
    line-height: 24px;
    font-size: 13px;
    white-space: nowrap;
  }
  .sensor-dot span {
    display: none;
  }
  .sensor-dot:hover {
    background: rgba(0, 0, 0, 0.65);
    color: #fff;
  }
  .sensor-dot span {
    display: inline-block;
  }
  .sensor-dot-success:before {
    background: #34c27e;
  }
  .sensor-dot-warning:before {
    background: #c9e4ca;
  }
  .sensor-dot-danger:before {
    background: #de3c4b;
  }
  
  /* Temporary no glow fix */
  button {
    outline: none;
    box-shadow: none !important;
  }
  
  /* react-paginate stuff */
  
  .pagination {
    display: flex;
    justify-content: space-between;
    list-style: none;
    cursor: pointer;
  }
  
  .pagination a {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #6c7ac9;
    color: #6c7ac9;
  }
  
  .pagination__link {
    font-weight: bold;
  }
  
  .pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
    color: #fff;
    background: #6c7ac9;
  }
  
  .pagination__link--disabled a {
    color: rgb(198, 197, 202);
    border: 1px solid rgb(198, 197, 202);
  }
  
  /*#587c84; - Soundsensing color*/
  
  a{
    color: #177185;
  }
  
  .card-hover:hover{
    transition: background-color 0.5s ease-out;
    background-color: #cdd8da;
    cursor: pointer;
  }