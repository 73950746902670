.header-container {
  padding: 0 20% 0 20%;
  z-index: 1;
  background-color: #ffffff;
}

.header-container a,
.change a {
  color: #ffffff;
  text-decoration: none;
}

.change a {
  padding: 0.5rem;
}

.header-container a:hover,
.change a:hover {
  padding-bottom: 3px;
  border-bottom: 2px solid #ffffff;
}

.header-logo {
  height: 5.5vmin;
  pointer-events: none;
}

.change {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  top: 0;
  z-index: 100;
  overflow: hidden;
}

@media only screen and (max-width: 768px) {
  .header-container a {
    display: none;
  }
}

@media mi {
  .header-container {
    padding: 20px;
  }
}
