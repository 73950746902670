.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #00000099;
  }

  .modal-main .tw-w-full {
    width: 100%; 
  }
  
  .modal-main {
    position: fixed;
    background: #F4F2ED;
    width: 22em;
    height: auto;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
  }
  
  .modal.display-block {
    display: block;
  }
  
  .modal.display-none {
    display: none;
  }

  h1{
    font-size: 40px;
  }